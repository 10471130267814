export function getMetaDataFromCookies(cookies: any) {
  let metaData = {
    userAgent: window.navigator.userAgent,
    viewPortHeightPx: window.innerHeight,
    viewPortWidthPx: window.innerWidth,
  };

  if (process.env.VUE_APP_BUILD_FOR_KOBI) {
    metaData = { ...metaData, ...getMetaDataFromKobiCookies(cookies) };
  }
  return metaData;
}

function getMetaDataFromKobiCookies(kobiCookies: any) {
  const userDoesConsentToTracking =
    kobiCookies?.get && kobiCookies.get('OptanonConsent') && kobiCookies.get('OptanonConsent').includes
      ? kobiCookies.get('OptanonConsent').includes('C0002:1')
      : false;

  const metadata: Record<any, string> = {};
  const addIfNotNull = (name: string, cookieName: string) => {
    const value = kobiCookies.get(cookieName);
    if (value !== null && value !== undefined) {
      metadata[name] = value;
    }
  };

  if (userDoesConsentToTracking) {
    addIfNotNull('googleAnalyticsClientId', '_ga');
    addIfNotNull('tagCommanderId', 'TCID');
  }
  addIfNotNull('storeId', 'lmfr_store_id');

  return metadata;
}
