<template>
  <div class="login">
    <div class="login-card">
      <div class="login-card__container">
        <div class="login-card__container-icon">
          <m-icon
            icon="https://customer-project.adeo.cloud/project-booster-media/mozaic-icons/svg/User_Account_Profile--View_64px.svg"
            size="l"
          ></m-icon>
        </div>
        <div class="login-card__container-title">Rechercher par identifiant client</div>
        <m-text-input placeholder="Identifiant du client" v-model="clientIdValue" class="login-card__container-input" />
        <m-button
          label="Rechercher"
          left-icon="https://customer-project.adeo.cloud/project-booster-media/mozaic-icons/svg/Navigation_Display_Search_24px.svg"
          width="full"
          @click="searchProject"
        />
      </div>
    </div>
    <div class="login-card">
      <div class="login-card__container">
        <div class="login-card__container-icon">
          <m-icon
            icon="https://customer-project.adeo.cloud/project-booster-media/mozaic-icons/svg/Media_Project_64px.svg"
            size="l"
          ></m-icon>
        </div>
        <div class="login-card__container-title">Rechercher par identifiant projet</div>
        <m-text-input
          placeholder="Identifiant du projet"
          v-model="projectIdValue"
          class="login-card__container-input"
        />
        <m-button
          label="Rechercher"
          left-icon="https://customer-project.adeo.cloud/project-booster-media/mozaic-icons/svg/Navigation_Display_Search_24px.svg"
          width="full"
          @click="searchProject"
        />
      </div>
    </div>
    <m-flex direction="row" align-items="center" justify-content="center" class="login-notification">
      <m-notification title="Remplir le champs" type="danger" v-if="error" :close="closeNotification" />
    </m-flex>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import MTextInput from 'project-booster-vue/src/components/mozaic/text-input/MTextInput';
import MNotification from 'project-booster-vue/src/components/mozaic/notifications/MNotification';
import MFlex from 'project-booster-vue/src/components/mozaic/flex/MFlex';
import MIcon from 'project-booster-vue/src/components/mozaic/icon/MIcon';
import MButton from 'project-booster-vue/src/components/mozaic/buttons/MButton';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'PbProjectsSearch',

  components: {
    MTextInput,
    MNotification,
    MFlex,
    MIcon,
    MButton,
  },

  data() {
    return {
      error: false,
      clientIdValue: '',
      projectIdValue: '',
    };
  },

  computed: {
    ...mapGetters({
      loginHref: 'auth/getUrl',
    }),
  },

  methods: {
    searchProject() {
      if (this.clientIdValue === '' && this.projectIdValue === '') {
        this.error = true;
        setTimeout(() => (this.error = false), 1000);
      } else {
        if (this.clientIdValue !== '') {
          this.$router.push({ name: 'ProjectsList', params: { clientId: this.clientIdValue } });
        } else {
          this.$router.push({ name: 'ProjectHub', params: { projectId: this.projectIdValue } });
        }
      }
    },

    closeNotification() {
      this.error = false;
    },
  },
});
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';

$responsive-breakpoint: 'm';

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include set-from-screen($responsive-breakpoint) {
    flex-direction: row;
  }

  .login-card {
    @include set-border-radius('m');
    @include set-box-shadow('m');
    @include set-font-face('regular');

    align-content: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    margin: $mu200;
    overflow: hidden;
    padding: $mu200 $mu150;
    position: relative;
    width: auto;

    @include set-from-screen($responsive-breakpoint) {
      width: 100%;
    }

    &__container {
      &-title {
        @include set-font-scale('06', 'm');
        @include set-font-face('semi-bold');

        align-self: stretch;
        color: $color-font-dark;
        flex: none;
        flex-grow: 0;
        font-style: normal;
        margin: $mu100;
        order: 0;
        text-align: center;
      }

      &-icon {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      &-input {
        margin: $mu200 0;
      }
    }
  }

  &-notification {
    bottom: 0;
    position: fixed;
  }
}
</style>
