import axios from 'axios';
import { setTcPage, eventHandler, buildEventApi, emitEvent } from '@/project-booster-shared-lib';
import { buildInteracted, getInhabitantProjectFromContext } from './utils';

const sendMediaEvent = buildEventApi('media', axios);

export const setupEventHandlers = () => {
  eventHandler({
    mediaHubLanded: (payload: any, context: any) => {
      const inhabitantProjectId = getInhabitantProjectFromContext(context);
      sendMediaEvent({
        name: 'start',
        businessUnit: '001',
        inhabitantProjectId,
      }).catch((e) => console.error(e));
    },
    mediaListLanded: (payload: any, context: any) => {
      setTcPage({
        typeContenu: 'compte internaute',
        template: 'media list',
        pageName: 'media list',
        typePage: 'my projects',
      });
    },
    mediaUploadLanded: () => {
      setTcPage({
        typeContenu: 'compte internaute',
        template: 'media upload',
        pageName: 'media upload',
        typePage: 'my projects',
      });
    },
    mediaUploadValidation: (
      {
        imgData: { ext, height, size, width, fileMimeType },
        validation,
      }: {
        imgData: { ext: string; height: number; size: number; width: number; fileMimeType: string };
        validation: boolean | any;
      },
      context: any,
    ) => {
      const correlationId = context.media.mediaCorrelationId;
      const inhabitantProjectId = getInhabitantProjectFromContext(context);
      const isAllValid = typeof validation === 'boolean' && validation === true;
      const { invalidFileType, fileTooBig, imageDimensionTooSmall, imageDimensionTooTall } = validation;

      sendMediaEvent({
        name: 'qualify-by-the-front',
        businessUnit: '001',
        correlationId,
        inhabitantProjectId,
        mediaSizeInBytes: size,
        mediaSizeQualification: isAllValid || !fileTooBig ? 'OK' : 'NOK',
        mediaType: fileMimeType || ext,
        mediaTypeQualification: isAllValid || !invalidFileType ? 'OK' : 'NOK',
        mediaHeightInPixel: height,
        mediaWidthInPixel: width,
        mediaResolutionQualification: isAllValid || (!imageDimensionTooSmall && !imageDimensionTooTall) ? 'OK' : 'NOK',
      }).catch((e) => console.error(e));
    },
    mediaDetailLanded: (payload: any, context: any) => {
      setTcPage({
        typeContenu: 'compte internaute',
        template: 'media detail',
        pageName: 'media detail',
        typePage: 'my projects',
      });
      emitEvent('interactedMedia', {}, context);
    },
    shareMedia: (payload: any, context: any) => {
      const inhabitantProjectId = getInhabitantProjectFromContext(context);
      sendMediaEvent({
        name: 'share',
        businessUnit: '001',
        inhabitantProjectId,
        mediaId: payload.context.mediaId,
        socialNetwork: payload.context.socialNetwork,
      }).catch((e) => console.error(e));
    },
    interactedMedia: buildInteracted('media', axios),
    mediaHubExited: (payload: any, context: any) => {
      const inhabitantProjectId = getInhabitantProjectFromContext(context);
      sendMediaEvent({
        name: 'end',
        businessUnit: '001',
        inhabitantProjectId,
      }).catch((e) => console.error(e));
    },
  });
};
