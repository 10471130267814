import axios from 'axios';
import { sendGaEvent, eventHandler, buildEventApi, emitEvent } from '@/project-booster-shared-lib';
import { buildInteracted, getInhabitantProjectFromContext } from './utils';

const sendConfiguratorEvent = buildEventApi('simulations', axios);

const sendConfiguratorEventPrefill = (name: string, context: any, extraPayload?: any) => {
  const inhabitantProjectId = getInhabitantProjectFromContext(context);
  sendConfiguratorEvent({
    name,
    inhabitantProjectId,
    businessUnit: '001',
    source: 'Projects_space',
    sourceDetail: 'Projects_space',
    ...extraPayload,
  }).catch((e) => console.error(e));
};

export const setupEventHandlers = () => {
  eventHandler({
    configuratorClicked: (payload: any, context: any) => {
      emitEvent('interactedConfigurator', {}, context);
      sendConfiguratorEventPrefill('startConfigurator', context, {
        configuratorId: payload.typeId,
      });
      sendGaEvent('Project-Space', 'Launch-of-configurator', payload.typeId);
    },
    interactedConfigurator: buildInteracted('simulation', axios),
    configuratorClickedFromImport: (payload: any, context: any) => {
      sendConfiguratorEventPrefill('startFromLookup', context, {
        configuratorId: payload.typeId,
      });
      sendGaEvent('Project-Space', 'Launch-of-configurator', payload.typeId);
    },
    importConfigurationRequested: (payload: any, context: any) => {
      sendConfiguratorEventPrefill('wantToImportSimulation', context);
      emitEvent('interactedConfigurator', {}, context);
    },
    configurationClickedInSection: (payload: any, context: any) => {
      emitEvent('interactedConfigurator', {}, context);

      sendConfiguratorEventPrefill('clickOnSimulationForEdit', context, {
        configuratorId: payload.configuration.configuratorId,
        simulationId: payload.configuration.simulationId,
      });
      sendGaEvent(
        'Project-Space',
        'Edit-of-simulation',
        payload.configuration.configuratorId + '-' + payload.configuration.simulationId,
      );
    },
  });
};
