import { AxiosStatic } from 'axios';
import { config } from './index';

export const buildEventApi = (target: string, axios: AxiosStatic) => async (event: any) => {
  const baseUrl = config.VUE_APP_PROJECT_BOOSTER_EVENT_BASE_URL ?? '/project-booster/api';
  const apiKey = config.VUE_APP_PROJECT_BOOSTER_EVENT_API_KEY ?? '';
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  if (apiKey) {
    headers['X-ClientApiKey'] = apiKey;
  }

  const response = await axios.post(baseUrl + `/events/${target}`, event, {
    headers,
  });
  return response.data;
};
