import { ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActionContext } from 'vuex';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmptyEventState {}

type EmptyEventContext = ActionContext<EmptyEventState, any>;

const eventBus = new ReplaySubject();

export const eventBus$ = eventBus.asObservable();

export const onEvent = (code: string) => (obs: Observable<any>) => obs.pipe(filter((data) => data.code === code));
export const onEvents = (codes: string[]) => (obs: Observable<any>) =>
  obs.pipe(filter((data) => codes.includes(data.code)));

if (process.env.NODE_ENV !== 'production') {
  eventBus$.subscribe((data) => {
    // eslint-disable-next-line no-console
    console.log('event bus data : ', data);
  });
}

export const eventHandler = (callMap: Record<string, any>) => {
  return eventBus$.pipe(onEvents(Object.keys(callMap))).subscribe(({ payload, context, code }) => {
    callMap[code](payload, context);
  });
};

export const emitEvent = (code: string, payload: any, state: EmptyEventState) => {
  eventBus.next({ code, payload, context: state });
};

export const eventBusVuexMutations = {
  eventBusSendEvent(state: EmptyEventState, { code, payload }: { code: string; payload: any }) {
    emitEvent(code, payload, state);
  },
};

export const eventBusVuexActions = {
  sendEventToBus({ commit }: EmptyEventContext, action: any) {
    commit('eventBusSendEvent', action);
  },
};
