<template>
  <div class="pb-login">
    <div class="pb-login__title">Vous avez été déconnecté de l'espace projet client</div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PbLogin',
});
</script>

<style lang="scss" scoped>
@import 'settings-tools/_all-settings';

.pb-login {
  @include set-font-face('regular');
  @include set-font-scale('07', 'm');

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    padding: $mu200 $mu100;
    text-align: center;
  }
}
</style>
