import { answerGettersMappers, getAnswerComponentType, getAnswers, getAnswerType, titleMappers } from './index';
import { Scenario, ScenarioStep, ScenarioAnswer, ScenarioStepAnswers, ScenarioStepAnswer } from '@/types/pb/Scenario';

const finalQuestions = ['PbRestitution'];

const answerMappers: Record<string, any> = {
  PbCard: ({ code, viewModel }: { code: string; viewModel: any }) => ({
    value: code,
    card: { img: viewModel.image, title: viewModel.title, subtitle: viewModel.text },
  }),
};

const removeEmpty = (obj: Record<string, any>) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key]);
    } else if (obj[key] == null) {
      delete obj[key];
    }
  });
};

export function hasNewFormId(step: ScenarioStep | undefined) {
  return !!getNewFormId(step);
}

export function getNewFormId(step: ScenarioStep | undefined) {
  return step && step.meta ? step.meta.newFormId : undefined;
}

export function hasNewEstimatorId(step: ScenarioStep) {
  const newEstimatorId = getNewEstimatorId(step);
  return !!newEstimatorId || newEstimatorId === '';
}

export function getNewEstimatorId(step: ScenarioStep) {
  return step?.meta?.newEstimatorId;
}

export function isStartedStepFinal(step: ScenarioStep) {
  return finalQuestions.includes(step?.component || 'other');
}

export function shouldIgnoreAnswer(step: ScenarioStep) {
  return step?.code === 'ESTIMATE_NAME';
}

export function shouldSendEveryAnswers(step: ScenarioStep) {
  return step?.meta?.shouldSendEveryUpdateForMultiSelect;
}

export function getDataForStep(rawAnswers: Record<string, any>, step: ScenarioStep, isMultiPartial = false) {
  const { component } = step;
  const title = titleMappers[component!]?.(step as ScenarioStep);
  let answers = [];

  if (isMultiPartial) {
    answers = Object.keys(rawAnswers);
  } else {
    answers = rawAnswers.map(answerGettersMappers[component!]);
  }

  const maybePossibleAnswers = getAnswers[component!]?.(step);
  const maybeAnswerComponentType: string | undefined = getAnswerComponentType[component!]?.(step as ScenarioStep);
  let possibleAnswers: ScenarioStepAnswer[] = [];
  if (maybePossibleAnswers && maybeAnswerComponentType) {
    const mapper = answerMappers[maybeAnswerComponentType];
    possibleAnswers = Object.values(maybePossibleAnswers).map(mapper);
  }

  const toSend = {
    questionLabel: title,
    answers,
    answerType: getAnswerType[component!],
    questionId: (<ScenarioStep>step).code,
    possibleAnswers,
  };

  // Big data api doesn't accept null values
  removeEmpty(toSend);

  return toSend;
}

export function adaptAnswers(answers: Map<string, ScenarioStepAnswer[]>, scenario: Record<string, any>) {
  let adaptedAnswers: ScenarioAnswer[] = [];

  if (answers) {
    adaptedAnswers = Object.entries(Object.fromEntries(answers))
      .filter(([code]) => !['PbRestitution'].includes(scenario[code].component ?? ''))
      .filter(([code]) => code !== 'SUBPROJECT_FORM_ID')
      .map(([questionId, answerData]) => ({
        questionId,
        answers: answerData.map(answerGettersMappers[scenario[questionId].component ?? ''] ?? (() => undefined)),
      }));
  }

  return adaptedAnswers;
}

export function toProjectNameAnswer(answer: { projectName: string; optin: string }) {
  const { projectName, optin } = answer;
  return { projectName, optin };
}

export function adaptSteps(steps: Map<string, ScenarioStepAnswer[]>) {
  const adaptedAnswers: Record<string, any> = {};

  for (const stepCode in Object.fromEntries(steps)) {
    const step: any[] | undefined = steps.get(stepCode);
    adaptedAnswers[stepCode] = { answers: [] };

    if (step) {
      for (const answer of step) {
        adaptedAnswers[stepCode].answers.push(
          (answer.amount ? Number.parseFloat(answer.amount) : null) ??
            (answer.projectName ? toProjectNameAnswer(answer) : null) ??
            answer.code ??
            answer.id ??
            answer.simulationId ??
            null,
        );

        adaptedAnswers[stepCode].meta = answer.meta;
      }
    }
  }

  return adaptedAnswers;
}
