import { ScenarioStep, ScenarioStepAnswer, ScenarioStepAnswers, ScenarioStepPayload } from '@/types/pb/Scenario';

export const titleMappers: Record<string, ({ payload }: ScenarioStep) => void> = {
  PbQuestion: ({ payload }) => payload?.viewModel.label,
  PbRestitution: () => 'validation',
  PbConclusion: ({ payload }) => payload?.viewModel.label,
  PbSpaceInput: ({ payload }) => payload?.viewModel.label,
  PbCitySearch: ({ payload }) => payload?.viewModel.label,
  PbNameInput: () => 'record',
  PbAmountInput: ({ payload }) => payload?.viewModel.label,
  PbDimensionsInput: ({ payload }) => payload?.viewModel.label,
  PbListSelect: ({ payload }) => payload?.viewModel.label,
  PbProjectCreated: () => 'PbProjectCreated',
  PbLoginRedirect: () => 'validation',
};

export const getAnswerType: Record<string, string> = {
  PbQuestion: 'string',
  PbRestitution: 'string',
  PbConclusion: 'string',
  PbNameInput: 'string',
  PbSpaceInput: 'number',
  PbCitySearch: 'string',
  PbListSelect: 'string',
  PbDimensionsInput: 'object',
};

export const answerGettersMappers: Record<string, (args: any) => void> = {
  PbQuestion: ({ code }: { code: string }) => code,
  PbRestitution: ({ code }: { code: string }) => code,
  PbConclusion: ({ code }: { code: string }) => code,
  PbSpaceInput: ({ space }: { space: string }) => parseFloat(space),
  PbCitySearch: ({ city }: { city: any }) => city.inseeCode,
  PbNameInput: ({ projectName }: { projectName: string }) => projectName,
  PbDimensionsInput: ({ width, length }: { width: string; length: string }) => {
    return { width: parseFloat(width), length: parseFloat(length) };
  },
  PbListSelect: ({ value }: { value: any }) => value,
};

const noop = () => undefined;

export const getAnswers: Record<string, (args: any) => Record<string, ScenarioStepAnswer> | undefined> = {
  PbQuestion: ({ payload }: { payload: ScenarioStepPayload }): Record<string, ScenarioStepAnswer> => payload.answers,
  PbRestitution: noop,
  PbConclusion: noop,
  PbSpaceInput: noop,
  PbCitySearch: noop,
  PbDimensionsInput: noop,
  PbListSelect: noop,
  PbProjectDefinition: noop,
};

export const getAnswerComponentType: Record<string, (args: any) => string | undefined> = {
  PbQuestion: ({ payload }: { payload: any }) => payload.viewModel.answersComponent,
  PbRestitution: noop,
  PbConclusion: noop,
  PbSpaceInput: noop,
  PbDimensionsInput: noop,
  PbListSelect: noop,
  PbCitySearch: noop,
  PbProjectDefinition: noop,
};

export function getTitleFromStep(step: ScenarioStep) {
  const titleGenerator = titleMappers[step.component!];
  if (titleGenerator) {
    return titleGenerator(step);
  }
}

export function getTCTitleFromStep(step: ScenarioStep) {
  return `${getTitleFromStep(step)}`;
}
