import { sendGaEvent } from '@/project-booster-shared-lib';
import * as media from './mediaAnalytics';
import * as hub from './projectHubAnalytics';
import * as simulation from './simulationAnalytics';

export const sendRecorded = () => sendGaEvent('Declaration-de-projet', 'Recorded', 'Recorded');

export const setupEventHandlers = (cookies: any) => {
  [media, hub, simulation].forEach((item) => item.setupEventHandlers());
};
