import PbLogin from '@/components/PbLogin.vue';
import PbMedia from 'project-booster-vue/src/components/media/PbMedia.vue';
import PbProjectHub from 'project-booster-vue/src/components/projects/project-hub/PbProjectHub.vue';
import PbProjectsList from 'project-booster-vue/src/components/projects/projects-list/PbProjectsList.vue';
import PbProjectsSearch from '@/components/PbProjectsSearch.vue';
import DOCUMENT_DETAIL_PAYLOAD from 'project-booster-vue/src/components/media/detail/document-detail-payload.json';
import DOCUMENT_UPLOAD_PAYLOAD from 'project-booster-vue/src/components/media/upload/document-upload-payload.json';
import { Store } from 'vuex';
import { NavigationGuardNext, NavigationGuardWithThis, RouteLocationNormalized } from 'vue-router';

const scrollToTop = () => {
  (<any>window).scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const loadProjects = async (
  store: Store<any>,
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  let userId = to.params?.clientId;
  if (!userId && store.getters['projects/getUserId']) {
    userId = store.getters['projects/getUserId'];
    if (userId) {
      next({ path: `/client/${userId}/projects` });
    } else {
      next({ name: 'ProjectsSearch' });
    }
  }

  store.dispatch('sendEventToBus', { code: 'projectListLanded', payload: {} });
  if (
    store.getters['projects/getUserId'] !== userId ||
    !store.getters['projects/getProjects'] ||
    store.getters['projects/getProjects']?.length === 0
  ) {
    await store.dispatch('projects/refreshProjectsListForUserId', userId);
    store.dispatch('sendEventToBus', { code: 'projectsListLoaded', payload: {} });
  }
};

const loadProject = async (store: Store<any>, to: RouteLocationNormalized) => {
  if (to?.params?.projectId) {
    try {
      const projectId = to.params.projectId;
      if (store.getters['configurations/getCurrentProjectId'] !== projectId) {
        store.dispatch('configurations/loadConfigurations', { projectId: projectId, forceNoPriceUpdate: true });
      }
      if (store.getters['estimates/getCurrentProjectId'] !== projectId && !localStorage.estimateToSave) {
        store.dispatch('estimates/loadEstimates', projectId);
      }
      if (store.getters['documents/getCurrentProjectId'] !== projectId) {
        store.dispatch('documents/loadMedia', projectId);
      }
      if (store.getters['projects/getCurrentProjectId'] !== projectId) {
        store.dispatch('sendEventToBus', {
          code: 'projectHubLanded',
          payload: { projectId: projectId, projectToLoad: true },
        });
        await store.dispatch('projects/loadProjectForUserId', projectId);
        store.dispatch('sendEventToBus', {
          code: 'projectHubProjectLoaded',
          payload: store.getters['projects/getProject'],
        });
        store.dispatch('projects/loadAppointment');
        store.dispatch('projects/loadProjectAttributes');
      } else {
        store.dispatch('sendEventToBus', {
          code: 'projectHubLanded',
          payload: { projectId: projectId, projectToLoad: false },
        });
      }
      const inhabitantId = store.getters['projects/getProject']?.ownerId;
      if (store.getters['inhabitants/getCurrentInhabitantId'] !== inhabitantId) {
        store.dispatch('inhabitants/loadInhabitant', inhabitantId);
      }
    } catch (error) {
      console.error('Could not load project', error);
    }
  }
};

const loadDocumentDetail = async (store: Store<any>, to: RouteLocationNormalized) => {
  if (to?.params?.projectId && to?.params?.documentId) {
    const projectId = to.params.projectId;
    const mediaId = to.params.documentId;
    if (store.getters['documents/getCurrentProjectId'] !== projectId) {
      store.dispatch('documents/loadMedia', projectId);
    }
    if (store.getters['documents/getCurrentMedia']?.id !== mediaId) {
      store.dispatch('documents/loadMedium', { projectId, mediaId });
    }
  }
};

export default (store: Store<any>) => [
  {
    path: '/',
    redirect: '/projects-search',
  },
  {
    name: 'ProjectsSearch',
    path: '/projects-search',
    component: PbProjectsSearch,
  },
  {
    name: 'ProjectsList',
    path: '/client/:clientId?/projects',
    component: PbProjectsList,
    props: (route: RouteLocationNormalized) => ({
      showCreateProject: false,
      title: `Client N°${route.params.clientId}`,
      subtitle: 'Liste des projets',
    }),
    meta: {
      loader: loadProjects,
    },
  },
  {
    name: 'ProjectHub',
    path: '/projects/:projectId',
    component: PbProjectHub,
    props: () => ({
      showBackButton: false,
      title: 'Liste de projets',
      linkLabel: `Tous les projets du client ${
        store.getters && store.getters['projects/getProject']
          ? 'N° ' + store.getters['projects/getProject']?.ownerId
          : ''
      }`,
      titleEstimation: 'Estimations de budget',
      titleSimulation: 'Simulations',
      titlePhotoInspiration: 'Photos et inspirations',
      titleDocument: 'Documents',
      titleProjectAttribute: 'Synthèse des informations projet',
      readOnly: true,
      estimatesPayload: {
        viewModel: {
          emptyStateTitle: '',
          emptyStateSubtitle: 'Aucune estimation réalisée',
        },
      },
      projectLoadPayload: {
        view: {
          titleLoading: 'Chargement du projet',
          subtitleLoading: 'Merci de patienter quelques instants',
          titleError: 'Le projet du client est introuvable',
          subtitleError: 'Il a peut-être été supprimé',
          retryButtonLabel: 'Réessayer',
        },
        creation: {
          titleLoading: 'Création du projet en cours',
          subtitleLoading: 'Merci de patienter quelques instants',
          titleError: 'Oups... Petit souci technique',
          subtitleError: 'Voulez-vous réessayer de sauvegarder le projet ?',
          retryButtonLabel: 'Réessayer',
        },
      },
    }),
    meta: {
      loader: loadProject,
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      next();
    },
  },
  {
    name: 'PbDocuments',
    path: '/projects/:projectId/documents',
    component: PbMedia,
    props: {
      sectionTitle: 'Mes documents',
      detailPayload: DOCUMENT_DETAIL_PAYLOAD,
      uploadPayload: DOCUMENT_UPLOAD_PAYLOAD,
      readOnly: true,
    },
    meta: {
      loader: loadProject,
    },
  },
  {
    name: 'PbDocumentDetail',
    path: '/projects/:projectId/documents/:documentId',
    component: PbMedia,
    props: {
      sectionTitle: 'Mes documents',
      detailPayload: DOCUMENT_DETAIL_PAYLOAD,
      uploadPayload: DOCUMENT_UPLOAD_PAYLOAD,
      readOnly: true,
    },
    meta: {
      loader: loadDocumentDetail,
    },
  },
  { name: 'Login', path: '/login', component: PbLogin },
];

export const globalBeforeGuard =
  (store: Store<any>) =>
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (to.name !== 'Login' && !store.getters['auth/getLoggedUser']) {
      await store.dispatch('auth/checkLoggedUser');

      if (!store.getters['auth/getLoggedUser']) {
        (<any>window).location =
          '/login?host=' + encodeURIComponent(location.hostname) + '&state=' + encodeURIComponent(to.fullPath);
      }
    }

    const params = new URLSearchParams((<any>window).location.search);
    if (params.has('state')) {
      const hash = params.get('state');
      (<any>window).location = `${(<any>window).location.origin + (<any>window).location.pathname}#${hash}`;
    }

    if (to.meta?.loader) {
      await (<any>to.meta).loader(store, to, from, next);
    }

    next();
  };
