import { createApp } from 'vue';
import { createStore } from 'vuex';
import { createRouter, createWebHashHistory } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import storeOptions from './stores/store';
import generateRoutes, { globalBeforeGuard } from './router/router';
import App from './App.vue';

const app = createApp(App);
const store = createStore(storeOptions);
app.use(store);
app.config.globalProperties.$store = store;

// To get customerId and Google Analytic ID
const { cookies } = useCookies();
app.config.globalProperties.$cookies = cookies;

const router = createRouter({
  history: createWebHashHistory(),
  routes: generateRoutes(store),
});
router.beforeEach(globalBeforeGuard(store));
app.use(router);
app.config.globalProperties.$router = router;

(<any>app.config).productionTip = false;

app.mount('#app');
