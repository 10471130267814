import { isConnected, logout } from '@/services/authentification';
import { ActionContext } from 'vuex';
import { State } from '@/stores/state';

export interface AuthenticationState {
  isCheckingLoggedUser: boolean;
  loggedUser: any;
  checkLoggedUserError: Error;
}

type AuthenticationContext = ActionContext<AuthenticationState, State>;

export default {
  namespaced: true,

  state: {
    isCheckingLoggedUser: false,
    loggedUser: null,
    checkLoggedUserError: null,
  },

  getters: {
    isCheckingLoggedUser: (state: AuthenticationState) => state.isCheckingLoggedUser,
    getLoggedUser: (state: AuthenticationState) => state.loggedUser,
    getCheckLoggedUserError: (state: AuthenticationState) => state.checkLoggedUserError,
  },

  mutations: {
    setIsCheckingLoggedUser(state: AuthenticationState, isCheckingLoggedUser: boolean) {
      state.isCheckingLoggedUser = isCheckingLoggedUser;
    },
    setLoggedUser(state: AuthenticationState, loggedUser: any) {
      state.loggedUser = loggedUser;
    },
    setCheckLoggedUserError(state: AuthenticationState, error: Error) {
      state.checkLoggedUserError = error;
    },
  },

  actions: {
    async logout({ commit }: AuthenticationContext) {
      commit('setLoggedUser', null);
      await logout();
    },

    async checkLoggedUser({ commit }: AuthenticationContext) {
      commit('setIsCheckingLoggedUser', true);
      commit('setLoggedUser', null);
      commit('setCheckLoggedUserError', null);

      try {
        const response = await isConnected();
        commit('setLoggedUser', response.userId);
        (<any>window).PB_TOKEN_FIREBASE_ID = response.firebaseToken;
      } catch (error) {
        console.error(error);
        commit('setCheckLoggedUserError', error);
      } finally {
        setTimeout(() => {
          commit('setIsCheckingLoggedUser', false);
        }, 1000);
      }
    },
  },
};
