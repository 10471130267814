import appointmentQualificationStore from 'project-booster-vue/src/stores/modules/appointmentQualificationStore';
import configurationsStore from 'project-booster-vue/src/stores/modules/configurationsStore';
import estimatesStore from 'project-booster-vue/src/stores/modules/estimatesStore';
import inhabitantsStore from 'project-booster-vue/src/stores/modules/inhabitantsStore';
import metadataStore from 'project-booster-vue/src/stores/modules/metaDataStore';
import projectsStore from 'project-booster-vue/src/stores/modules/projectsStore';
import toolsStore from 'project-booster-vue/src/stores/modules/toolsStore';
import documentsStore from 'project-booster-vue/src/stores/modules/documentsStore';
import consentStore from 'project-booster-vue/src/stores/modules/consentStore';
import productsStore from 'project-booster-vue/src/stores/modules/productsStore';
import trezorStore from 'project-booster-vue/src/stores/modules/trezorStore';

import authModule from './modules/authentification';
import { vuexfireMutations } from 'vuexfire';
import { eventBusVuexActions, eventBusVuexMutations } from './../project-booster-shared-lib';
import cloneDeep from 'lodash.clonedeep';
import { StoreOptions } from 'vuex';

export default {
  modules: {
    auth: authModule,
    appointmentQualification: appointmentQualificationStore,
    configurations: configurationsStore,
    estimates: estimatesStore,
    inhabitants: inhabitantsStore,
    metaData: metadataStore,
    projects: projectsStore,
    tools: toolsStore,
    documents: cloneDeep(documentsStore),
    documentsPlans: cloneDeep(documentsStore),
    documentsPictures: cloneDeep(documentsStore),
    consent: consentStore,
    products: productsStore,
    trezor: trezorStore,
  },
  mutations: {
    ...vuexfireMutations,
    ...eventBusVuexMutations,
  },
  actions: {
    ...eventBusVuexActions,
  },
  strict: true,
} as StoreOptions<any>;
