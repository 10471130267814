import { buildEventApi } from '@/project-booster-shared-lib';
import axios, { AxiosStatic } from 'axios';

const sendProjectSpaceEvent = buildEventApi('project-spaces', axios);

const sendProjectInteracted = async (event: { scope: string; inhabitantProjectId: string }) => {
  return sendProjectSpaceEvent({
    ...event,
    businessUnit: '001',
    name: 'interacted',
  });
};

export const getInhabitantProjectFromContext = (context: any) => context.projects?.currentProjectId;

export const buildInteracted = (scope: string, axios: AxiosStatic) => (payload: any, context: any) => {
  const inhabitantProjectId = getInhabitantProjectFromContext(context);
  sendProjectInteracted({
    scope,
    inhabitantProjectId,
  }).catch((e) => console.error(e));
};
