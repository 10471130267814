import axios from 'axios';

const isConnected = async () => {
  const response = await axios.get('/me');
  return response.data;
};

const logout = async () => {
  const response = await axios.post('/logout');
  return response.data;
};

export { isConnected, logout };
