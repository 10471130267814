import axios from 'axios';
import { setTcPage, eventHandler, buildEventApi, eventBus$, onEvent, sendGaEvent } from '@/project-booster-shared-lib';
import { debounceTime } from 'rxjs/operators';
import { getInhabitantProjectFromContext } from './utils';
import { Project } from 'project-booster-vue/src/types/pb/Project';

const sendProjectSpaceEvent = buildEventApi('project-spaces', axios);

function setProjectDetailsTcVars(project: Project) {
  const domesticSpaceId = project?.domesticSpace?.href.replace('/domestic-spaces/', '');
  const projectType = project?.projectType?.href.replace('/project-types/', '');
  const projectLabel = project?.projectType?.label;
  setTimeout(() => {
    setTcPage({
      template: 'project detail',
      typeContenu: 'compte internaute',
      pageName: `project detail_${domesticSpaceId}`,
      typePage: 'my projects',
      pbProjectType: `${projectType} - ${projectLabel}`,
    });
  }, 100);
}

export const setupEventHandlers = () => {
  eventBus$.pipe(onEvent('projectHubLanded'), debounceTime(100)).subscribe(({ context }) => {
    const projectId = getInhabitantProjectFromContext(context);
    if (projectId === 'new') {
      return;
    }
    sendProjectSpaceEvent({
      businessUnit: '001',
      name: 'landed',
      inhabitantProjectId: projectId,
    }).catch((e) => console.error(e));
  });
  eventHandler({
    'projectListLanded': (data: any, state: any) => {
      if (state.projects.totalProjects != null) {
        setTcPage({
          template: 'project list home',
          typeContenu: 'compte internaute',
          pageName: `project list home_${state.projects.totalProjects}`,
          typePage: 'my projects',
        });
      }
    },
    'projectsListLoaded': (data: any, state: any) => {
      const pageName = state.projects.projectsLoadError !== null ? 'NA' : state.projects.totalProjects;
      setTcPage({
        template: 'project list home',
        typeContenu: 'compte internaute',
        pageName: `project list home_${pageName}`,
        typePage: 'my projects',
      });
    },
    'landedAfterDeclarationHubMode': (payload: any) => {
      sendProjectSpaceEvent({
        businessUnit: '001',
        name: 'landedAfterDeclaration',
        inhabitantProjectId: payload,
      }).catch((e) => console.error(e));
    },
    'projectHubLanded': ({ projectToLoad }: any, state: any) => {
      const project = state.projects.project;
      if (project && !projectToLoad) {
        setProjectDetailsTcVars(project);
      }
    },
    'projectHubProjectLoaded': (payload: any) => {
      setProjectDetailsTcVars(payload);
    },
    'PROJECT-SPACE-CREATE-PROJECT-EMPTY-STATE': () => {
      sendGaEvent('project space', 'project creation', 'click empty state');
    },
    'UPDATE-MAIL-CONSENT': (data: any, state: any) => {
      sendProjectSpaceEvent({
        businessUnit: '001',
        name: 'update-mail-optin',
        inhabitantProjectId: state.projects?.currentProjectId,
      }).catch((e) => console.error(e));
    },
    'RETRY-UPDATE-MAIL-CONSENT': (data: any, state: any) => {
      sendProjectSpaceEvent({
        businessUnit: '001',
        name: 'retry-update-mail-optin',
        inhabitantProjectId: state?.projects?.currentProjectId,
      }).catch((e) => console.error(e));
    },
    'DISPLAY-MAIL-CONSENT': (data: any, state: any) => {
      sendProjectSpaceEvent({
        businessUnit: '001',
        name: 'display-mail-optin',
        inhabitantProjectId: state.projects?.currentProjectId,
      }).catch((e) => console.error(e));
    },
    'appointmentLinkClicked': (payload: any) => {
      sendProjectSpaceEvent({
        businessUnit: '001',
        name: 'appointmentLinkClicked',
        inhabitantProjectId: payload,
      }).catch((e) => console.error(e));
    },
  });
};
