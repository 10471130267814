export const waitForGlobal = (key: string, callback: any, tries = 0) => {
  if (tries > 20) {
    return;
  }
  if ((<any>window)[key]) {
    callback();
  } else {
    setTimeout(() => {
      waitForGlobal(key, callback, tries + 1);
    }, 100);
  }
};
