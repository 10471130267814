<template>
  <div id="collaborator-app">
    <pb-header
      v-if="!isCheckingLoggedUser && !checkLoggedUserError"
      logo="https://customer-project.adeo.cloud/project-booster-media/common/logo-lm.svg"
      title="Espace projet client"
      :subtitle="currentUser ? `Connecté en tant que ${currentUser}` : null"
      :logout-label="currentUser ? 'Se déconnecter' : null"
      @logout-click="logout"
    />
    <main class="content">
      <pb-loader
        class="loader"
        title-loading="Chargement en cours"
        title-success="Chargement terminé"
        title-error="Erreur de connexion"
        subtitle-loading="Veuillez patienter..."
        subtitle-error="Veuillez nous excuser"
        :state="loaderState"
        @click="checkLoggedUser"
        v-if="isCheckingLoggedUser || checkLoggedUserError"
      />
      <div class="container" v-else>
        <pb-projects />
      </div>
      <m-notification :text="error" title="Erreur" type="danger" v-if="error" />
    </main>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import PbHeader from 'project-booster-vue/src/components/header/PbHeader';
import PbLoader from 'project-booster-vue/src/components/loader/PbLoader';
import PbProjects from 'project-booster-vue/src/components/projects/projects/PbProjects.vue';
import MNotification from 'project-booster-vue/src/components/mozaic/notifications/MNotification';
import { mapGetters } from 'vuex';
import { setupEventHandlers } from './services/webAnalyticsTracker';
import { getMetaDataFromCookies } from '@/project-booster-shared-lib/metadata';

export default defineComponent({
  name: 'App',

  components: {
    PbHeader,
    PbLoader,
    PbProjects,
    MNotification,
  },

  data() {
    return {
      error: null,
    };
  },

  computed: {
    ...mapGetters('auth', {
      isCheckingLoggedUser: 'isCheckingLoggedUser',
      currentUser: 'getLoggedUser',
      checkLoggedUserError: 'getCheckLoggedUserError',
    }),
    loaderState() {
      return this.isCheckingLoggedUser ? 'loading' : this.checkLoggedUserError ? 'error' : 'success';
    },
  },

  created() {
    setupEventHandlers(this.cookies);
    const metaData = getMetaDataFromCookies(this.$cookies);
    this.$store.dispatch('metaData/updateMetaData', metaData);
  },

  methods: {
    checkLoggedUser() {
      window.location.reload();
    },

    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'Login' });
    },
  },
});
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';

body {
  margin: 0 !important;
}

#collaborator-app {
  height: 100vh;

  .content {
    display: flex;
    justify-content: center;

    .loader {
      padding-top: 20%;
    }

    .container {
      max-width: 1024px;
      padding: $mu200 0 0;
      width: 100%;
    }
  }
}

.pb-project-hub__inhabitant {
  margin-top: 0 !important;
}
</style>
